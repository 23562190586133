<template>
  <!-- <div class="text-center"> -->
  <v-dialog v-model="dialog" @click:outside="clickOutside" fullscreen>
    <template v-slot:activator="{ on, attrs }">
      <p id="processLot" v-bind="attrs" v-on="on" @click="update"></p>
    </template>

    <div
      v-if="result != null"
      class="d-flex justify-center align-center"
      style="position: relative"
    >
      <v-card
        elevation="2"
        tile
        outlined
        style="position: relative; padding: 10px; z-index: 5; height: 100vh"
      >
        <div
          style="
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            padding: 5px 0;
            margin-bottom: 15px;
          "
        >
          <v-btn
            style="position: absolute; top: 0; right: 0"
            icon
            small
            color="black"
            @click="close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <h6 style="text-align: center; margin: 0; color: black">
            {{ result.lot.lot_no || '-' }}
          </h6>
        </div>
        <v-row no-gutters style="padding: 15px 10px">
          <v-col cols="12" style="padding: 5px 0">
            <v-text-field
              dense
              :value="result.lot.lot_no || '-'"
              readonly
              label="Lot"
              style="margin: 0; font-size: 12px"
            />
          </v-col>
          <v-col cols="12" style="padding: 5px 0">
            <v-text-field
              dense
              :value="result.lot.asset.name || '-'"
              readonly
              label="Nama Aset"
              style="margin: 0; font-size: 12px"
            />
          </v-col>
          <v-col cols="12" style="padding: 5px 0">
            <v-text-field
              dense
              :value="result.lot.asset.code || '-'"
              readonly
              label="Kode"
              style="margin: 0; font-size: 12px"
            />
          </v-col>
          <v-col cols="12" style="padding: 5px 0">
            <v-text-field
              dense
              :value="result.lot.company_name || '-'"
              readonly
              label="Kepemilikan"
              style="margin: 0; font-size: 12px"
            />
          </v-col>
          <v-col cols="12" style="padding: 5px 0">
            <v-text-field
              dense
              :value="result.placed_location_name || '-'"
              readonly
              label="Lokasi terima"
              style="margin: 0; font-size: 12px"
            />
          </v-col>
          <v-col cols="12" style="padding: 5px 0">
            <v-text-field
              dense
              :value="result.received_by_name || '-'"
              readonly
              label="Diterima oleh"
              style="margin: 0; font-size: 12px"
            />
          </v-col>
          <v-col cols="12" style="padding: 5px 0">
            <v-text-field
              dense
              :value="result.rejected_by_name || '-'"
              readonly
              label="Ditolak oleh"
              style="margin: 0; font-size: 12px"
            />
          </v-col>
          <v-col cols="12" style="padding: 5px 0; margin-bottom: 5px">
            <v-text-field
              dense
              :value="itemStatus(result.status) || '-'"
              readonly
              label="Status"
              style="margin: 0; height: 40px; font-size: 12px; color: red"
            />
          </v-col>
          <v-col cols="7">
            <div v-if="result.status == 0">
              <v-autocomplete
                v-if="result.lot !== null"
                v-model="result.lot.placed_location"
                :items="getDropdownLocation"
                item-text="name"
                item-value="id"
                return-object
                outlined
                dense
                style="margin: 0; height: 40px; font-size: 12px"
                label="-Terima di gudang -"
                clearable
                hide-no-data
                :disabled="result.lot.status == -1"
              ></v-autocomplete>
            </div>
          </v-col>
          <v-col cols="1"></v-col>
          <v-col cols="4" class="d-flex justify-center align-center">
            <div
              v-if="result.status == 0"
              style="
                padding: 0;
                display: flex;
                flex-direction: row;
                justify-content: felx-start;
                align-items: center;
              "
            >
              <v-checkbox
                v-if="result.lot != null"
                v-model="result.lot.status"
                :value="-1"
                color="red"
                style="margin: 0; height: 25px; padding: 0"
                :disabled="result.lot.placed_location"
              ></v-checkbox>
              <p style="margin-bottom: 0; margin-right: 15px; font-size: 14px">
                Tolak
              </p>
            </div>
          </v-col>
          <v-col cols="12" style="margin-top: 30px">
            <v-btn
              :disabled="result.status != 0"
              @click="submit"
              elevation="1"
              large
              rounded
              color="indigo"
              class="white--text font-weight-bold"
              style="font-size: 12px; width: 100%"
            >
              Proses
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </v-dialog>
  <!-- </div> -->
</template>
<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import buildType from '../../../../services/buildType'
export default {
  name: 'Lot-Moving-Process',
  props: ['result', 'movementId'],
  data() {
    return {
      asset: buildType.apiURL('asset'),
      dialog: false
    }
  },
  computed: {
    ...mapGetters(['getUserProfile', 'getDropdownLocation'])
  },
  methods: {
    ...mapActions(['dropdownLocation']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    clickOutside() {
      console.log('outside')
    },
    close() {
      this.dialog = false
    },
    update() {
      console.log('update blbalbal')
    },
    submit() {
      const newArr = []
      if (this.result.lot.placed_location !== undefined) {
        if (this.result.lot.placed_location !== null) {
          newArr.push({
            id: this.result.id,
            lot_id: this.result.lot.id,
            lot_no: this.result.lot.lot_no,
            placed_location_id: this.result.lot.placed_location.id,
            placed_location_name: this.result.lot.placed_location.name
          })
        }
      }

      if (this.result.lot.status !== undefined) {
        if (this.result.lot.status !== null) {
          if (this.result.lot.status === -1) {
            newArr.push({
              id: this.result.id,
              lot_id: this.result.lot.id,
              lot_no: this.result.lot.lot_no,
              status: this.result.lot.status
            })
          }
        }
      }

      this.showMsgDialog('question', 'Simpan sekarang', true).then((res) => {
        if (res.isConfirmed) {
          this.createNewForm(newArr, this.movementId)
        }
      })
    },
    createNewForm(arr, id) {
      const newForm = {
        id: id,
        item: arr
      }
      this.save(newForm)
    },

    save(form) {
      axios
        .post(`${this.asset}movement/receive`, form)
        .then((res) => {
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.dialog = false
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          this.$emit('loadPage')
        })
        .catch((err) => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    itemStatus(v) {
      switch (v) {
        case -1:
          return 'Rejected'
        case 0:
          return 'Pending'

        case 1:
          return 'Received'
        default:
          return 'null'
      }
    }
  }
}
</script>
<style scoped></style>
